import * as React from "react";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Project } from "../types";


const Root = styled.span`
display: block;
width: 100%;
border-radius: 22px;
height: 300px;
position: relative;

* {
    z-index: 3;
}

&:before {
    content: "";
    
    display: block;
    position: absolute;
    inset: -1px;
    z-index: -1;
    border-radius: 22px;
}

&:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    background: white;
    border-radius: 22px;
}

&:hover {
    &:after {
        box-shadow: var(--card-hover-shadow);
    }
}
`;

const ImageParent = styled.span`
height: 200px;
display: block;
border-top-left-radius: 22px;
border-top-right-radius: 22px;
overflow: hidden;
scroll-snap-type: x mandatory;
scroll-behavior: smooth;

img {
    width: 100%;
    object-fit: cover;
    scroll-snap-align: start;
}
`;

const ProjectParent = styled.span`
display: block;
position: relative;
`;

const ProjectMeta = styled.span`
display: block;
padding: 30px;

span.title,
span.description {
font-size: 12px;
font-weight: 500;
color: rgb(142, 147, 155);
display: block;
}

span.description {
font-size: 13px;
margin-top: 10px;
}
`;

const ProjectsParent = styled.span`
display: block;
position: relative;
height: 100%;
overflow: auto;
border-radius: 22px;

${ProjectParent} {
    &:not(:last-child) {
        border-bottom: 1px solid var(--bs-border-color);
    }
}
`;

const LinkList = styled.ul`
list-style: none;
margin: 0;
padding: 0;
display: flex;
gap: 20px;
margin-top: 10px;

@media(max-width: 768px) {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
}
`;

const LinkListItem = styled.li`
font-size: 13px;
margin-top: 10px;
text-decoration: underline;
`;

type ProjectsProps = Omit<React.ComponentProps<'span'>, "ref"> & {
    projects: Array<Project>
};

const Projects: React.FC<ProjectsProps> = ({ projects, ...rest }) => {
    // Create refs for each project's image container
    const imageRefs = useRef<(HTMLSpanElement | null)[]>([]);
    const pausedStates = useRef<boolean[]>([]);
    const resumeTimeouts = useRef<(NodeJS.Timeout | null)[]>([]);
    const intervals = useRef<(NodeJS.Timeout | null)[]>([]);
    
    // Set up auto-sliding for images
    useEffect(() => {
        // Initialize pause states for each project
        pausedStates.current = projects.map(() => false);
        resumeTimeouts.current = projects.map(() => null);
        intervals.current = projects.map(() => null);
        
        // Function to start the auto-sliding interval for a project
        const startInterval = (projectIndex: number) => {
            if (intervals.current[projectIndex]) {
                clearInterval(intervals.current[projectIndex]!);
            }
            
            const project = projects[projectIndex];
            if (project.imageUrl && project.imageUrl.length > 1) {
                const imageContainer = imageRefs.current[projectIndex];
                if (!imageContainer) return;
                
                let currentImageIndex = 0;
                
                intervals.current[projectIndex] = setInterval(() => {
                    if (pausedStates.current[projectIndex]) return;
                    
                    currentImageIndex = (currentImageIndex + 1) % project.imageUrl!.length;
                    const scrollPosition = currentImageIndex * imageContainer.offsetWidth;
                    imageContainer.scrollTo({ left: scrollPosition, behavior: 'smooth' });
                }, 1000);
            }
        };
        
        // Set up event handlers for each project with images
        projects.forEach((project, projectIndex) => {
            if (!project.imageUrl || project.imageUrl.length <= 1) return;
            
            const imageContainer = imageRefs.current[projectIndex];
            if (!imageContainer) return;
            
            // Start the interval
            startInterval(projectIndex);
            
            // Mouse enter handler - pause on hover
            const handleMouseEnter = () => {
                pausedStates.current[projectIndex] = true;
            };
            
            // Mouse leave handler - resume after hover
            const handleMouseLeave = () => {
                pausedStates.current[projectIndex] = false;
            };
            
            // Scroll handler - pause on manual scroll
            const handleScroll = () => {
                // Pause the automatic scrolling
                pausedStates.current[projectIndex] = true;
                
                // Clear any existing timeout
                if (resumeTimeouts.current[projectIndex]) {
                    clearTimeout(resumeTimeouts.current[projectIndex]!);
                }
                
                // Resume auto-scrolling after 2 seconds of no manual scrolling
                resumeTimeouts.current[projectIndex] = setTimeout(() => {
                    pausedStates.current[projectIndex] = false;
                }, 2000);
            };
            
            // Add event listeners
            imageContainer.addEventListener('mouseenter', handleMouseEnter);
            imageContainer.addEventListener('mouseleave', handleMouseLeave);
            imageContainer.addEventListener('scroll', handleScroll);
            
            // Return cleanup function
            return () => {
                imageContainer.removeEventListener('mouseenter', handleMouseEnter);
                imageContainer.removeEventListener('mouseleave', handleMouseLeave);
                imageContainer.removeEventListener('scroll', handleScroll);
                
                if (intervals.current[projectIndex]) {
                    clearInterval(intervals.current[projectIndex]!);
                }
                
                if (resumeTimeouts.current[projectIndex]) {
                    clearTimeout(resumeTimeouts.current[projectIndex]!);
                }
            };
        });
        
        // Clean up intervals on unmount
        return () => {
            intervals.current.forEach((interval) => {
                if (interval) clearInterval(interval);
            });
            
            resumeTimeouts.current.forEach((timeout) => {
                if (timeout) clearTimeout(timeout);
            });
        };
    }, [projects]);
    
    return <Root className="muted" {...rest}>
        <ProjectsParent>
        {projects.map((p, index) => <ProjectParent key={p.title}>
            {p.imageUrl && <ImageParent 
                className="d-flex gap-1 overflow-auto h-2"
                ref={el => imageRefs.current[index] = el}
            >
                {p.imageUrl.map((img, imgIndex) => <img key={imgIndex} alt={p.title} src={img} />)}
            </ImageParent>}
            <ProjectMeta>
                <span className="title text-muted">{p.title} &middot; <span>{p.date}</span></span>
                <span className="description">{p.description}</span>
                <LinkList>{p.links.map(l => <LinkListItem key={l.title}><a className="muted" rel="noreferrer" target="_blank" href={l.url}>{l.title}</a></LinkListItem>)}</LinkList>
            </ProjectMeta>
        </ProjectParent>)}
        </ProjectsParent>
    </Root>
}

export default Projects;