import * as React from "react";
import styled from "styled-components";
import projects from "../projects";
import { useTitle } from "../utils";


const Root = styled.div`
`;

const Project = styled.div`
img {
    width: 100%;
    height: 20em;
    object-fit: cover;
    margin-bottom: 1.5rem;
    border: 1px solid #eee;
    border-radius: 10px;
}
`;

const LinkList = styled.ul`
list-style: none;
margin: 0;
padding: 0;
display: flex;
gap: 20px;

@media(max-width: 768px) {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
}
`;

const LinkListItem = styled.li`
`;

type ViewProps = {};

const Projects: React.FC<ViewProps> = () => {
    useTitle("Projects");
    return <Root>
        <div>
            <h3>Projects</h3>
            <p className="text-muted">(Some) projects I've done on my own time.</p>
        </div>
        <hr className="my-5" />
        <div className="pb-5">
            {
                projects.map(p => {
                    return <Project key={p.title} className="mb-5 pb-4">
                        {p.imageUrl && <div className="d-flex gap-2 overflow-auto">
                            {p.imageUrl.map(img => <img alt={p.title} src={img} />)}
                        </div>}
                        <h5>{p.title} &middot; <span className="text-muted">{p.date}</span></h5>
                        <p className="text-muted">{p.description}</p>
                        <LinkList>{p.links.map(l => <LinkListItem key={l.title}><a className="muted" rel="noreferrer" target="_blank" href={l.url}>{l.title}</a></LinkListItem>)}</LinkList>
                    </Project>
                })
            }
        </div>
    </Root>
}

export default Projects;